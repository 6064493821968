import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { CustomForm } from '@avesdo-common/src/lib/feature/custom-forms/services/custom-form-model.service';
import { SelectionSubmissionTemplate } from '../../../models/SelectionSubmissionTemplate';
import { PaymentRequirementDraftCheque } from '@avesdo-common/src/lib/enums/PaymentRequirement';
import { BuyerChoiceCard } from '../../../models/BuyerChoiceCard';
import { AvCustomControlTypes } from '@avesdo-common/src/lib/feature/custom-forms/enums/AvCustomControlTypes';
import { FloorRange } from '@avesdo-common/src/lib/feature/selection/models/FloorRange';

@Component({
  selector: 'lib-selection-submission-accordion',
  templateUrl: './selection-submission-accordion.component.html',
  styleUrls: ['./selection-submission-accordion.component.scss']
})
export class SelectionSubmissionAccordionComponent implements OnInit {
  @Input() originalSelectionTemplate: SelectionSubmissionTemplate;
  @Input() formGroup: FormGroup;
  @Input() additionalNotesMaxLength: number;
  @Input() floorRanges: FloorRange[];
  @Input() readonly: boolean;

  @Input() idUploadForm: CustomForm;
  @Input() idUploadForm2: CustomForm;
  @Input() buyerInfoForm: CustomForm;
  @Input() buyer2InfoForm: CustomForm;
  @Input() realtorInfoForm: CustomForm;
  @Input() fillRealtorInfoForm: boolean;
  @Input() fillBuyer2InfoForm: boolean;
  @Input() annualIncomeForm: CustomForm;
  @Input() annualIncomeForm2: CustomForm;

  @Output() openUnitPicker = new EventEmitter<{ index: number }>();
  @Output() removeChoice = new EventEmitter<BuyerChoiceCard>();
  @Output() increasePriority = new EventEmitter<FormGroup>();
  @Output() decreasePriority = new EventEmitter<FormGroup>();

  readonly AvCustomControlTypes = AvCustomControlTypes;
  readonly PaymentRequirementDraftCheque = PaymentRequirementDraftCheque

  step = 0;

  constructor() { }

  //#region Getters


  get selectionChoicesControl() {
    return this.formGroup.get('selectionChoices') as FormArray;
  }

  get additionalNotesControl() {
    return this.formGroup.get('additionalNotes');
  }

  get bankDraftFrontUrlControl() {
    return this.formGroup.get('bankDraftFrontUrl');
  }
  get bankDraftBackUrlControl() {
    return this.formGroup.get('bankDraftBackUrl');
  }

  get buyerChoiceCards(): BuyerChoiceCard[] {

    const properties: { id: number }[] = this.originalSelectionTemplate.properties;

    return this.selectionChoicesControl.value.map((control, i) => ({
      property: properties.find(property => property?.id === control.propertyId),
      formGroupControl: this.selectionChoicesControl.controls[i]
    }));
  }

  get choicesRemaining() {
    const { maxNumberOfChoices } = this.originalSelectionTemplate.settings;
    return `${maxNumberOfChoices - this.selectionChoicesControl.length}/${maxNumberOfChoices}`;
  }

  get photoIdFrontControl() {
    return this.idUploadForm.getFormControlByLabel('Upload Photo ID #1');
  }

  get photoIdBackControl() {
    return this.idUploadForm.getFormControlByLabel('Upload Photo ID #2');
  }

  get photoIdFrontControl2() {
    return this.idUploadForm2.getFormControlByLabel('Upload Photo ID #1');
  }

  get photoIdBackControl2() {
    return this.idUploadForm2.getFormControlByLabel('Upload Photo ID #2');
  }

  get properties() {
    return this.originalSelectionTemplate.properties;
  }

  get noPaymentRequirement() {
    return this.originalSelectionTemplate.settings.paymentRequirement === PaymentRequirementDraftCheque.None;
  }

  //#endregion

  //#region Public Methods

  isPanelOpen(panelIndex: number): boolean {
    return this.step === panelIndex;
  }

  setStep(index: number) {
    this.step = index;
  }

  closePanel(index: number) {
    //User toggled already opened panel
    if (index === this.step)
      this.step = null;
  }

  nextStep(formGroup: FormGroup = undefined, formGroup2: FormGroup = undefined, formGroup3: FormGroup = undefined) {
    formGroup && formGroup.markAllAsTouched();
    formGroup2 && formGroup2.markAllAsTouched();
    formGroup3 && formGroup3.markAllAsTouched();
    this.step++;

    if (!this.fillBuyer2InfoForm && this.step === 1) {
      //this.step++;
      this.nextStep();
    }

    if (!this.fillRealtorInfoForm && this.step === 2) {
      //this.step++;
      this.nextStep();
    }

    if (this.step == 4) {
      this.nextStep();
    }
    
    if (this.step == 5) {
      this.nextStep();
    }

    if (this.noPaymentRequirement && this.step === 6) {
      //this.step++;
      this.nextStep();
    }

    //For Local Testing Purposes
    // if(!this.formGroup.valid){
    //   console.log('this.formGroup.valid', this.formGroup.valid);
    //   console.log(this.formGroup);
    //   console.error(this.collectErrors(this.formGroup));
    // }

  }

  isFormGroup(control: AbstractControl): control is FormGroup {
    return !!(<FormGroup>control).controls;
  }
  collectErrors(control: AbstractControl): any | null {
    let errors = {};
    let recursiveFunc = (control: AbstractControl) => {
      if (this.isFormGroup(control)) {
        return Object.entries(control.controls).reduce(
          (acc, [key, childControl]) => {
            const childErrors = recursiveFunc(childControl);
            if (childErrors) {
              if (!this.isFormGroup(childControl)) {
                errors = { ...errors, [key]: childErrors };
              }
              acc = { ...acc, [key]: childErrors };
            }
            return acc;
          },
          null
        );
      } else {
        return control.errors;
      }
    };
    recursiveFunc(control);
    return errors;
  }

  prevStep() {
    this.step--;

    if (!this.fillBuyer2InfoForm && this.step === 1) {
      //this.step--;
      this.prevStep();
    }

    if (!this.fillRealtorInfoForm && this.step === 2) {
      //this.step--;
      this.prevStep();
    }

    if (this.step == 4) {
      this.prevStep();
    } 
    
    if (this.step == 5) {
      this.prevStep();
    }

    if (this.noPaymentRequirement && this.step === 6) {
      //this.step--;
      this.prevStep();
    }
  }

  getFormStatus({ formGroup }: CustomForm): string {
    if (formGroup.valid) {
      return 'full';
    } else if (formGroup.touched && formGroup.invalid) {
      return 'partial';
    }

    return '';
  }

  getGroupBuyerFormStatus(form1: CustomForm, form2: CustomForm, images: string[]): string {
    var statusForm1 = this.getFormStatus(form1);
    var statusForm2 = this.getFormStatus(form2);
    var statusForm3 = this.getFileUploadFormStatus(images);
    if(statusForm1 == 'full' && statusForm2 == 'full' && statusForm3 == 'full')
      return 'full';
    else if(statusForm1 == 'partial' || statusForm2 == 'partial' || statusForm3 == 'partial'){
      return 'partial'
    }
    return '';
  }

  getChoicesStatus(): string {
    if (this.selectionChoicesControl.valid) {
      return 'full';
    } else if (this.selectionChoicesControl.dirty && this.selectionChoicesControl.invalid) {
      return 'partial';
    }

    return '';
  }

  getFileUploadFormStatus(images: string[]): string {
    const imagesFilledLength = images.filter((value) => value).length;

    if (imagesFilledLength === images.length) {
      return 'full';
    } else if (imagesFilledLength) {
      return 'partial'
    } else {
      return ''
    }
  }

  //#endregion

  ngOnInit(): void {
  }

}
