export const FinancialInstitutions = {
  RBC: 'RBC',
  RBCLong: 'Royal Bank of Canada',
  TD: 'TD',
  TDLong: 'Canada Trust',
  BMO: 'BMO',
  BMOLong: 'Bank of Montreal',
  CIBC: 'CIBC',
  CIBCLong: 'Canadian Imperial Bank of Commerce',
  Scotiabank: 'Scotiabank',
  ScotiabankLong: 'The Bank of Nova Scotia'
}
