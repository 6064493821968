import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentTypes } from '../../../feature/contracts-new/modules/shared/enums/payment-types';
import { PaymentService } from '@ng-new/src/app/feature/contracts-new/modules/reporting/services/payment.service';
import { FinancialInstitutions } from '../../../feature/contracts-new/modules/shared/enums/financial-institutions';
import { map, startWith } from 'rxjs/operators';
import { NotificationService } from '@avesdo-common/src/lib/services/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { IPayment } from '../../../feature/contracts-new/modules/reporting/models/IPayment';
import { ConfirmationDialogService } from '@avesdo-common/src/lib/services/dialogs/confirmation-dialog.service';

interface PaymentDetailsData {
  payment?: IPayment;
  isNewPayment?: boolean;
  packageId?: number;
  excludeEft?: boolean;
}
@Component({
  selector: 'app-payment-details-dialog',
  templateUrl: './payment-details-dialog.component.html',
  styleUrls: ['./payment-details-dialog.component.scss']
})
export class PaymentDetailsDialogComponent implements OnInit {
  readonly fileSizeLimit: number = 3;// MB
  paymentForm: FormGroup;
  paymentNumbers = 9;
  paymentTypes = {...PaymentTypes};
  financialInstitutions$;
  paymentStatusList$;
  isNewPayment: boolean;
  isSubmitting: boolean = false;

  get isFileTooLarge() {
    return this.paymentForm.get('file').hasError('fileTooLarge');
  }

  get isFileEmpty() {
    const fileForm = this.paymentForm.get('file');
    return fileForm.touched && fileForm.hasError('required');
  }

  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
    public dialogRef: MatDialogRef<PaymentDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentDetailsData
  ) { }

  onAmountChange() {
    const amountForm = this.paymentForm.get('amount');
    amountForm.setValue((this.paymentForm.get('status').value === 'Refund' ? -1 : 1) * Math.abs(amountForm.value.toFixed(2)));
  }

  onFileSelected(files: File[] | FileList) {
    this.paymentForm.get('file').setValue(files[0]);
  }

  onSave() {
    if (!this.isFormValid()) {
      return;
    }
    this.isSubmitting = true;
    const payment = this.paymentForm.value;
    delete payment.file;
    if (!payment.note)
      delete payment.note;
    this.paymentService.addPayment({
      packageId: this.data.packageId,
      ...payment,
    },
      this.paymentForm.get('file').value
    ).subscribe(result => {
      if (result) {
        this.notificationService.success(this.translateService.instant('PAYMENT.ADD_SUCCESSFUL'));
        this.dialogRef.close(true);
      } else
        this.isSubmitting = false;
    }, () => this.isSubmitting = false);
  }

  onUpdate() {
    if (!this.isFormValid()) {
      return;
    }
    this.isSubmitting = true;
    const payment = this.paymentForm.value;
    if (!payment.note)
      delete payment.note;
    this.paymentService.updatePayment(payment).subscribe(result => {
      if (result) {
        this.notificationService.success(this.translateService.instant('PAYMENT.UPDATE_SUCCESSFUL'));
        this.dialogRef.close(true);
      } else
        this.isSubmitting = false;
    }, () => this.isSubmitting = false);
  }

  private validSizeLimit() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value?.size > this.fileSizeLimit * 1024 * 1024 ? { fileTooLarge: control.value.size } : null;
    }
  }
  
  private isFormValid() {
    if (this.paymentForm.valid) {
      return true;
    }
    this.paymentForm.markAllAsTouched();
    let onlyFileError = true;
    Object.entries(this.paymentForm.controls).forEach((control) => {
      if (control[0] === 'file') {
        return;
      }
      if (control[1].invalid) {
        onlyFileError = false;
      }
    });
    onlyFileError ? this.notificationService.error(this.translateService.instant('ERRORS.DOCUMENT_PICTURE_ADDED')) : this.notificationService.error(this.translateService.instant('ERRORS.PLEASE_FILL_ALL'));
    return false;
  }

  confirmClose() {
    if (this.paymentForm.pristine) {
      this.dialogRef.close();
      return;
    }
    this.confirmationDialog.openDialog({
      title: 'GENERIC.UNSAVED_CHANGES',
      message: 'GENERIC.UNSAVED_CHANGES_MSG',
      no: 'GENERIC.STAY',
      yes: 'GENERIC.LEAVE'
    }).afterClosed().subscribe(leave => {
      if (leave) {
        this.dialogRef.close();
      }
    });
  }

  private initializePaymentForm(payment: IPayment) {
    return this.formBuilder.group({
      paymentNumber: [payment?.paymentNumber, Validators.required],
      amount: [payment?.amount, Validators.required],
      status: [payment?.status, Validators.required],
      paymentType: [payment?.paymentType, Validators.required],
      receivedDate: [payment?.receivedDate, Validators.required],
      receivedBy: [payment?.receiveddBy],
      financialInstitution: [payment?.financialInstitution],
      accountNumber: [payment?.accountNumber],
      accountHolderName: [payment?.accountHolderName],
      note: [payment?.note],
      file: [undefined, [Validators.required, this.validSizeLimit()]]
    });
  }

  ngOnInit(): void {
    const { excludeEft, payment, isNewPayment } = this.data;
    if (excludeEft) {
      delete this.paymentTypes.eft;
    }
    this.paymentStatusList$ = this.paymentService.getPaymentDropdownStatus();
    this.isNewPayment = isNewPayment;
    this.paymentForm = this.initializePaymentForm(payment);
    this.financialInstitutions$ = this.paymentForm.get('financialInstitution').valueChanges.pipe(
      startWith(''),
      map(value => {
        const institutionNames = Object.values(FinancialInstitutions);
        return institutionNames.filter(option => option.toLowerCase().includes(value.toLowerCase()));
      }),
    );
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
  }

}
