import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {environment} from '../../environments/environment';

import {AvesdoCommonModule} from '@avesdo-common/src/lib/avesdo-common.module';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { SharedFn } from './redux/shared.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BuildingSettingsFn } from './redux/BuildingSettings/building-settings.reducer';
import { BuildingSettingsEffects } from './redux/BuildingSettings/building-settings.effects';
import { FeaturePermissionsFn } from './redux/FeaturePermissions/feature-permissions.reducer';
import { FeaturePermissionsEffects } from './redux/FeaturePermissions/feature-permissions.effects';
import { CreateRealtorDialogComponent } from './components/create-realtor-dialog/create-realtor-dialog.component';
import { PaymentDetailsDialogComponent } from './components/payment-details-dialog/payment-details-dialog.component';
import { TranslateLocalePipe } from './pipes/translate-locale.pipe';
import { AvTableDateComponent } from './components/av-table-date/av-table-date.component';
//export Required for Aot
export function HttpFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/locale/', '.json');
}

// @dynamic
@NgModule({
  declarations: [
    CreateRealtorDialogComponent,
    PaymentDetailsDialogComponent,
    AvTableDateComponent,
    TranslateLocalePipe,
  ],
  imports: [
    CommonModule,
    AvesdoCommonModule.forRoot(environment),
    StoreModule.forFeature('ngNewShared', SharedFn),
    StoreModule.forFeature('buildingSettings', BuildingSettingsFn),
    StoreModule.forFeature('featurePermissions', FeaturePermissionsFn),
    EffectsModule.forFeature([FeaturePermissionsEffects]),
    EffectsModule.forFeature([BuildingSettingsEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    AvesdoCommonModule,
    TranslateLocalePipe,
    CreateRealtorDialogComponent,
    AvTableDateComponent
  ]
})
export class SharedModule { 

  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
