export const EftPaymentStatus = {
  Pending: { label: 'Pending', value: 0, text: 'Pending' },  
  EftSetup: { label: 'EFT Setup', value: 1, text: 'EftSetup' },
  PadSigned: { label: 'PAD Signed', value: 2, text: 'PadSigned' },
  FundsReceived: { label: 'Funds Received', value: 3, text: 'FundsReceived' },
  Cancelled: { label: 'Cancelled', value: 4, text: 'Cancelled' },
  NonSufficientFunds: { label: 'Non Sufficient Funds', value: 5, text: 'NonSufficientFunds' },
  Overdue: { label: 'Overdue', value: 6, text: 'Overdue' },
  FailedDepositFundsTransfer: { label: 'Payment Failure', value: 7, text: 'FailedDepositFundsTransfer' },
  FailedServiceFeeFundsTransfer: { label: 'Payment Failure', value: 8, text: 'FailedServiceFeeFundsTransfer' },
  ProcessingFundsTransfer: { label: 'Processing EFT Payment', value: 9, text: 'ProcessingFundsTransfer' },
  DevelopmentAccountNotSetup: { label: 'Payment Failure', value: 10, text: 'DevelopmentAccountNotSetup' },
  FailedFundingAccount: { label: 'Payment Failure', value: 11, text: 'FailedFundingAccount' },
  FailedWithdrawingFunds: { label: 'Payment Failure', value: 12, text: 'FailedWithdrawingFunds' },
  NotApplicable: { label: 'N/A', value: 13, text: 'NotApplicable' },
  BankAccountNotConnected: { label: 'Payment Failure', value: 14, text: 'FailedTransferBankAccountNotConnected' }
}