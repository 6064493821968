import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AppConfigService } from '@avesdo-common/src/lib/services/feature-toggle/app-config.service';
import { AngularComponentsService } from '@ng-new/src/app/shared/services/angular-components.service';
import { updateActiveModule } from '../../shared/redux/shared.actions';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { ModuleNames } from '../../shared/enums/ModuleNames';
import { ConfigService } from '../../shared/services/config.service';
import { ImageCarouselModalService } from '@avesdo-common/src/lib/services/images/image-carousel-modal.service';
import { AuthService } from '@ng-new/src/app/auth/services/auth.service';
import { environment } from '@avesdo-common/src/lib/environments/environment';
import { LegacyRouteChangeService } from '../../shared/services/legacy-route-change.service';
import { AvHelperService } from '@avesdo-common/src/lib/services/avHelper/av-helper.service';
import { ExcelReportsService } from '../../feature/contracts/services/excel-reports.service';
import { CfgFormService } from '../../feature/contracts/services/cfg-form.service';
import { WebhooksService } from '../../feature/contracts/services/webhooks.service';
import { HttpClient } from '@angular/common/http';
import FileSaver from 'file-saver';
import { ConfirmationDialogService } from '@avesdo-common/src/lib/services/dialogs/confirmation-dialog.service';
import { AvBulkFileUploadDialogService } from '@avesdo-common/src/lib/services/dialogs/bulk-file-upload-dialog.service';
import { AngularAngularjsNavigateService } from '@avesdo-common/src/lib/services/angular-angularjs-navigate.service';
import { FileUploadService } from '@avesdo-common/src/lib/services/file-upload.service';
import { ManageEftPaymentService } from '../../feature/contracts-new/modules/reporting/services/manage-eft-payment.service';

declare var angular: any;
declare var $: any;

@Component({
  selector: 'app-angularjs-wrapper',
  templateUrl: './angularjs-wrapper.component.html',
  styleUrls: ['./angularjs-wrapper.component.scss']
})
export class AngularjsWrapperComponent implements AfterViewInit, OnDestroy {
  showCommonHeader: boolean;
  angularJsApp: any;

  constructor(
    private appConfigService: AppConfigService,
    private angularComponentsService: AngularComponentsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private configService: ConfigService,
    private authService: AuthService,
    private imageCarouselModalService: ImageCarouselModalService,
    private legacyRouteChangeSrvc: LegacyRouteChangeService,
    private avHelperSrvc: AvHelperService,
    private excelReportsSrvc: ExcelReportsService,
    private cfgFormSrvc: CfgFormService,
    private webhooksSrvc: WebhooksService, 
    private http: HttpClient,
    private confirmationDialogService: ConfirmationDialogService,
    private avBulkFileUploadService: AvBulkFileUploadDialogService,
    private fileUploadService: FileUploadService,
    private angularRouterNavigateService: AngularAngularjsNavigateService,
    private manageEftPaymentService: ManageEftPaymentService
  ) {
    this.showCommonHeader = true;
  }

  ngAfterViewInit() {
    this.store.dispatch(updateActiveModule({ activeModule: ModuleNames.Contracts}));
    //Make services available to AngularJS by adding them here
    const angularServices = {
      ngNewAppConfigService: this.appConfigService,
      ngNewComponentsService: this.angularComponentsService,
      ngNewRouter: this.router,
      ngNewActivatedRoute: this.activatedRoute,
      ngNewImageCarouselModalService: this.imageCarouselModalService,
      ngNewAuthService: this.authService,
      ngNewEnvironment: environment,
      ngNewLegacyRouteChangeSrvc: this.legacyRouteChangeSrvc,
      ngNewAvHelperSrvc: this.avHelperSrvc,
      ngNewExcelReportsService: this.excelReportsSrvc,
      ngNewCfgFormService: this.cfgFormSrvc,
      ngNewWebhooksService: this.webhooksSrvc,
      ngNewHttp: this.http,
      ngNewFileSaver: FileSaver,
      ngConfirmationDialogService: this.confirmationDialogService,
      ngBulkFileUploadService: this.avBulkFileUploadService,
      ngFileUploadService: this.fileUploadService,
      ngAngularRouterNavigateService: this.angularRouterNavigateService,
      ngManageEftPaymentService: this.manageEftPaymentService,

      //Keep Legacy constant and name
      yongleConstant: this.configService.yongleAppConstant
    };

    for (let key in angularServices) {
      angular.module('yongleApp').factory(key, () => {
        return angularServices[key]
      });
    }

    this.angularJsApp = angular.bootstrap(document.getElementById('yongleApp'), ['yongleApp']);
    const userAgent = navigator.userAgent.toLowerCase();
    const macOs = userAgent.indexOf('mac') !== -1;
    if(macOs){
      document.getElementById('yongleApp').classList.add('container-ios');
    }

    /* On Bootstrap 4 dropdowns events. Trigger click on document. Needed to close av-select. */
    $(document).on('show.bs.dropdown', function () {
      $(document).trigger('click');
    });
  }

  ngOnDestroy() {
    $(document).off('show.bs.dropdown');
    //Tear Down AngularJS app
    const $rootScope = this.angularJsApp.get('$rootScope');
    $rootScope.$destroy();
    angular.element('#yongleApp').empty();
  }
}
