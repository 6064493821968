import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { LegacyUserRole } from '@avesdo-common/src/lib/enums/LegacyUserRole';
import { AppConfigService } from '@avesdo-common/src/lib/services/feature-toggle/app-config.service';
import { EftPaymentStatus } from '@ng-new/src/app/shared/enums/EftPaymentStatus';
import { EftPaymentService } from './eftPayment.service';
import { AuthService } from '@ng-new/src/app/auth/services/auth.service';
import { EftPayment, EftPaymentCreatePayload } from '@ng-new/src/app/shared/models/EftPayment';
import { Package } from '@avesdo-common/src/lib/models/package/Package';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Deposit } from '@ng-new/src/app/shared/models/Deposit';

@Injectable({
  providedIn: 'root'
})
export class ManageEftPaymentService {
  maxDaysForEftPaymentChanges = this.appConfig.settings.maxDaysForEftPaymentChanges;
  maxDaysForEftPayorChanges = this.appConfig.settings.maxDaysForEftPayorChanges;
  dayDiffForEftExclusion = this.appConfig.settings.dayDiffForEftExclusion;

  constructor(
    private appConfig: AppConfigService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private eftPaymentService: EftPaymentService,
    private translateService: TranslateService
  ) { }

  // private pendingToEftSetupValidator(initialstatus): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors => {
  //     // We only check the days until due date for status changes from 'Pending' to 'EftSetup'
  //     if (this.isStatusChangeFromPendingToEftSetup(control.value, initialstatus)) {
  //       // If the number of days until due date is 35 or less, we have an error
  //       return this.getDaysUntilDueDate(control) <= this.maxDaysForEftPaymentChanges ? {pendingToEftSetup: 'pendingToEftSetup'} : undefined;
  //     }
  //     // If we're not changing from 'Pending' to 'EftSetup', we don't check the due date
  //     return;
  //   }
  // }

  private paymentUrlValidator(eftPaymentForm, packageDepositId): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const statusControl = this.getControl(eftPaymentForm, packageDepositId, 'status');
      if (!statusControl) {
        return;
      }
      if (statusControl.value === EftPaymentStatus.EftSetup.value && (!control.value || control.value.trim().length === 0)) {
        return {eftSetupError: 'eftSetupError'};
      }
    }
  }

  getControl(eftPaymentForm, id: any, controlName: string): FormControl {
    return eftPaymentForm?.get(`${id}`)?.get(controlName) as FormControl;
  }

  getEftPayments(packageId) {
    return this.eftPaymentService.getEftPayments(this.auth.buildingId, packageId);
  }

  beginEftPaymentForm(eftPayments: EftPayment[], deposits: Deposit[]): FormGroup {
    const eftPaymentForm = this.formBuilder.group({});
    deposits.forEach(deposit => {
      const eftPayment = eftPayments.find(payment => payment.packageDepositId === deposit.id);
      if (eftPayment) {
        eftPayment.packageDepositId ?? (eftPayment.packageDepositId = eftPayment.packageDeposit.id);
        eftPaymentForm.addControl(`${deposit.id}`, this.formBuilder.group({
          ...eftPayment,
          status: EftPaymentStatus[eftPayment.status].value,
          paymentUrl: [eftPayment.paymentUrl, this.paymentUrlValidator(eftPaymentForm, deposit.id)]
        }));
        return;
      }
      eftPaymentForm.addControl(`${deposit.id}`, this.formBuilder.group({
        ...new EftPayment({
          packageDeposit: deposit,
          amount: deposit.amount,
          payor: undefined
        }),
        paymentUrl: ['', this.paymentUrlValidator(eftPaymentForm, deposit.id)],
      }));
    });
    return eftPaymentForm;
  }

  enableEftChange(enableEft, eftPaymentForm, defaultPurchaser) {
    if (enableEft) {
      Object.values<FormControl>(eftPaymentForm.controls).forEach(payment => {
        payment.get('status').setValue(EftPaymentStatus.Pending.value);
        payment.get('payorEmail').setValue(defaultPurchaser);
      });
    }
  }

  validEft(payment: EftPayment): boolean {
    if (!payment.packageDeposit)// || !payment.packageDeposit.dueDate);
      return false;
    const validDate = payment.packageDeposit.dueDate ? this.getDaysUntilDueDate(payment.packageDeposit.dueDate) >= this.dayDiffForEftExclusion : true;
    const notFirstDeposit = payment.packageDeposit.depositOrder > 1;
    const nullPaymentId = !!payment.id;
    const isValid = (validDate || nullPaymentId) && notFirstDeposit;
    return isValid;
  }

  getDaysUntilDueDate(dueDate): number {
    const today = new Date();
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const dueDateWithoutTime = new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate());
    const millisBetween = dueDateWithoutTime.getTime() - todayWithoutTime.getTime();
    return Math.floor(millisBetween / (1000 * 60 * 60 * 24));
  }

  // isStatusChangeFromPendingToEftSetup(currentStatus, initialStatus): boolean {
  //   return initialStatus === EftPaymentStatus.Pending.text && currentStatus === EftPaymentStatus.EftSetup.value;
  // }

  disablePayorDropDown(eftPayment, isPackSoldFirm, eftEnabled) {
    const targetStatuses = [EftPaymentStatus.Pending.value, EftPaymentStatus.EftSetup.value];
    let daysUntilDueDate;
    if (eftPayment.packageDeposit.dueDate) 
      daysUntilDueDate = this.getDaysUntilDueDate(eftPayment.packageDeposit.dueDate)
    const disable = !targetStatuses.includes(eftPayment.status) || daysUntilDueDate <= this.maxDaysForEftPayorChanges;
    return !isPackSoldFirm || !eftEnabled || disable;
  }

  getStatusOptions(userRole: LegacyUserRole, payment, originalStatus) {
    if (userRole === LegacyUserRole.AvesdoCS) {
      return Object.values(EftPaymentStatus);
    } else {
      return Object.values(EftPaymentStatus).filter(option =>
        option.value === payment.status || option.label === EftPaymentStatus.Cancelled.label || option.value === originalStatus);
    }
  }

  cancelEft(eftPaymentForm, payment, confirmationDialog: (isCancelable: boolean) => any): Promise<boolean> {
    if (!payment.isCancelable) {
      return;
    }
    return this.eftPaymentService.checkCancelable(this.auth.buildingId, payment.id).toPromise().then(isCancelable => {
        const dialog = confirmationDialog(isCancelable);
        if (isCancelable)
          return dialog.then(cancel => {
            if (cancel)
              this.getControl(eftPaymentForm, payment.packageDepositId, 'status').setValue((EftPaymentStatus.Cancelled.value));
            return cancel;
          }, () => {});
        return false;
      });
  }

  update(pack: Package, eftPaymentForm: FormGroup, vopayConfirmationFn = () => {}): Observable<any> {
    let eftPayments: EftPayment[];
    const controlsList = Object.values(eftPaymentForm.controls);
    if (controlsList.map(control => control.get('paymentUrl')).find(control => control.invalid)) {
      return throwError(this.translateService.instant('POST_SALE.DEPOSIT.EFT_SETUP_MUST_HAVE_URL_MSG'));
    }

    eftPayments = Object.values<EftPayment>(eftPaymentForm.getRawValue()).filter(eft => this.validEft(eft));
    eftPayments.forEach(eftPayment => {
      eftPayment.isTentative = eftPayment.packageDeposit.isTentative;
      eftPayment.payor = pack.signers.find(p => (p.userDetail?.email || p.userDetail?.Email) === eftPayment.payorEmail);
      delete eftPayment.packageDeposit;
    });

    if (eftPayments.find(payment => payment.id)) {
      return this.eftPaymentService.updateEftPayments(this.auth.buildingId, pack.id, eftPayments);
    }

    return this.eftPaymentService.createEftPayments(this.auth.buildingId, pack.id, eftPayments.map(eft => new EftPaymentCreatePayload(eft))).pipe(
      map(eftPaymentCreation => {
        if (!eftPaymentCreation.hasVopayDeveloperAccount) {
          vopayConfirmationFn();
        }
      })
    );
  }
}
