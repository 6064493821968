import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@avesdo-common/src/lib/models/generic/ApiResponse';
import { handleCoreApiResponse } from '@avesdo-common/src/lib/operators/handleCoreApiResponse';
import { environment } from '@ng-new/src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IPayment } from '../models/IPayment';
import { EftPayment } from '@ng-new/src/app/shared/models/EftPayment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) { }

  addPayment(payment: IPayment | any, file: File): Observable<any> {
    const uri = `${environment.crmApiUrl}/Contracts/Payment/AddPayment`;
    const formData = new FormData();
    Object.entries(payment).forEach(([key, value]) => {
      key = key[0].toUpperCase() + key.substring(1);
      if (value instanceof Date) {
        formData.append(key, value.toISOString());
        return;
      }
      formData.append(key, value as any);

    });
    formData.append('file', file);
    
    return this.httpClient.post<ApiResponse<any>>(uri, formData).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  updatePayment(payment: IPayment): Observable<any> {
    const uri = `${environment.crmApiUrl}/Contracts/Payment/UpdatePayment`;
    return this.httpClient.post<ApiResponse<any>>(uri, {Payment: payment}).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }
  
  getPaymentReport(): Observable<any> {
    const uri = `${environment.crmApiUrl}/Contracts/Payment/GetPaymentReporting`;
    return this.httpClient.get<ApiResponse<any>>(uri).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }

  getPaymentDropdownStatus(): Observable<any> {
    const uri = `${environment.crmApiUrl}/Contracts/Payment/GetPaymentDropdownStatus`;
    return this.httpClient.get<ApiResponse<any>>(uri).pipe(
      handleCoreApiResponse,
      map((response) => response.data),
      catchError((error) => throwError(error))
    );
  }
}
