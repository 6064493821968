import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-av-text-button',
  templateUrl: './av-text-button.component.html',
  styleUrls: ['./av-text-button.component.scss']
})
export class AvTextButtonComponent implements OnInit {
  @Input() text: string;
  @Input() clickable: boolean;
  @Input() data: any;
  @Output() click = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (!this.text) {
      if (this.clickable) {
        this.text = '\xa0\xa0\xa0\xa0\xa0';// Add &nbsp; so link is still clickable
        return;
      }
      this.text = '';
    }
  }

}
