import { Inject, Injectable } from '@angular/core';
import { DateService } from './date/date.service';
import { Area } from '../enums/Area';

@Injectable({
  providedIn: 'root'
})
export class UtilityServices {
  constructor(
    private dateService?: DateService
  ) { }

  formatPhoneNumber(phone: string): string {
    if (phone == undefined)
      return '';
    phone.replace(/\D/g, '');
    if (phone.length == 11) {
      return phone.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3-$4');
    }
    if (phone.length == 10) {
      return phone.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3');
    }
    return phone;
  }

  fromMVCDate(mvcDate, ignoreTimezone?) {
    if (!mvcDate)
      return undefined;
    try {
      if (!mvcDate.match(/\/Date\(-?[\d].+\)\//g)?.length)
          return this.fromISODate(mvcDate, ignoreTimezone);
      const date = new Date(parseInt(mvcDate.replace('/Date(', '').replace(')/', ''), 10));
      const updatedDate = ignoreTimezone ? new Date(date.toUTCString().replace(" GMT", "")) : date;
      return updatedDate;
    } catch (e) {
      return mvcDate;
    }
  }

  fromISODate(isoDate, ignoreTimezone) {
    if (isoDate == null)
        return null;
    try {
        const isUTC = isoDate[isoDate.length - 1] === 'Z';
        const date = new Date(isoDate + (ignoreTimezone || isUTC ? '' : this.dateService.getTimeZoneOffset()));
        return date;
    } catch (e) {
        return isoDate;
    }
};

  checkIfAreaIsInPSTorMST(area) {
    let pstAreas = [Area.AB, Area.AU_EST, Area.BC, Area.US_CO];
    return pstAreas.includes(area);
  }

  getNetOfHst(price) {
    let result = 0;
    if (price <= 368200)
      result = price / 1.052;
    else if (price <= 424850)
      result = (price + 28350) / 1.133;
    else if (price <= 484500)
      result = (price + 52350) / 1.193;
    else
      result = (price + 24000) / 1.13;
    return result;
  }

  enumToObj(e: any): {[key: string]: string} {
    const arr = Object.values(e);
    const mid = arr.length / 2;
    const obj = {};
    for (let i = 0; i < mid; i++) {
      obj[arr[i] as string] = arr[i + mid];
    }
    return obj;
  }
}
