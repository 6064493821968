export const environment = {
  production: 'false',
  authority: 'https://avesdo-sso-uat.azurewebsites.net', // SSO Server URL
  analyticsUrl: 'https://avesdo-dev-analytics.azurewebsites.net', // Analytics API URL
  coreBaseUrl: 'https://uat.avesdo.net', // Core Avesdo URL,
  coreMarketplaceApiUrl: 'https://avesdo-uat-core-mp-api.azurewebsites.net',
  imgServerUrl: 'https://avesdouat.blob.core.windows.net',
  mktplaceBaseUrl: 'https://realtor-uat.offplan.homes',
  mktplaceDeveloperBaseUrl: 'https://developer-uat.offplan.homes',
  mktplaceApiUrl: 'https://avesdo-uat-offplan-api.azurewebsites.net/api', // MP API URL
  crmBaseUrl: 'https://crm-uat.avesdo.net',
  crmApiUrl: 'https://avesdo-uat-apigateway.azurewebsites.net',
  crmRerouteUrl : 'https://avesdo-dev-reroute.azurewebsites.net',
  buyersPortalApiUrl: '',
  buyersPortalCookieDomain: '',
  marketplaceCookieDomain: 'realtor-uat.offplan.homes',
  buyersPortalBaseUrl: '',
  utilityServiceUrl: 'https://avesdo-uat-utilityservice.azurewebsites.net/api',

  stripeApiKey: 'pk_test_oyA1ZUgjkc1yJGbooO8kbf2P00XzpBfbCG',
  //SSO variables
  googleClientId: '',
  microsoftClientId: '74e48e15-5290-4a59-9595-419a8f1634c2',
  microsoftAuthority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  redirectUrl: 'https://uat.avesdo.net/Account/EmailSettings',
  googleAuthority: '',
  googleApiScopeGmail: '',
  facebookAppId: '',
  customMsalClientId: '',
  msalSignUpAuthority: '',
  msalResetPasswordAuthority: '',

  //Google Maps
  googleMapApiKey: 'AIzaSyCGPhGXdo5fmhjM_kQDjsthS1vDLJyKAR0',
  hubSpotSrc: '',
  googleAnalyticsId: '',

  //Signal R
  signalRUrl: 'https://avesdo-uat-apigateway.azurewebsites.net/signalrhub/gridUpdate',

  environmentType: 'Uat'
};
