import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TrackByFunction
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { combineLatest, Subscription, Observable } from 'rxjs';
import { startWith, map, skip } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Dictionary } from '../../../models/generic/Dictionary';
import { AvHelperService } from '../../../services/avHelper/av-helper.service';
import { SortDirection } from '@angular/material/sort';
import { differenceWith, isEqual, property } from 'lodash';

export enum AvTableViewType {
  FunnelFilters = 'FunnelFilters',
  CombinedHeader = 'CombinedHeader',
  OnlyTable = 'OnlyTable'
}

export interface AvTableComponentConfig {
  loadingData: boolean;
  data: any[];
  columns: AvTableColumnConfig[];
  multiRow?: AvTableSubRowConfig[];
  showMultiRow?: (index: number, rowData: any) => boolean;
  debounceTime?: number;
  dataSelection?: SelectionModel<any>;
  totalsLabel?: string;
  pagination?: {
    totalPageCount: number;
    totalItemsCount: number;
    pageSize: number;
  },
  initialSearchState?: AvTableSearchState
  viewType?: AvTableViewType;
  rowHeight?: number;
  rowClick?: (row: Dictionary<any>) => void;
  displayFooter?: boolean;
  footerData?: {
    [column: string]: {
      displayText: string;
      className?: string;
    }
  };
}

export interface AvTableColumnConfig {
  propertyName?: string;
  displayName: string;
  sortName?: any;
  filterType?: FilterTypes
  multiSelectItems?: AvMultiSelectItem[];
  component?: {
    class: any;
    inputs?: Dictionary<any>,
    outputEventHandlers?: Dictionary<any>
  };
  isCurrency?: boolean;
  isSortDisabled?: boolean;
  displayIcon?: {
    class: string,
    tooltip?: string,
    click?: () => void
  },
  propertyType?: string;
  cellTemplate?: string;
  cellClass?: string;
  floatRight?: boolean;
  sticky?: 'start' | 'end';
}

export interface AvTableSubRowConfig {
  propertyName?: string;
  name: string;
  component?: {
    class: any;
    inputs?: Dictionary<any>,
    outputEventHandlers?: Dictionary<any>
  };
  propertyType?: string;
  cellTemplate?: string;
  colspan?: number;
}

export interface AvMultiSelectItem {
  label: string;
  value: any;
}

export enum FilterTypes {
  none = 'none',
  text = 'text',
  range = 'range',
  dropdown = 'dropdown',
  number = 'number',
  multiSelect = 'multiSelect',
  multiSelectLarge = 'multiSelectLarge'
}

export enum AvTableCellContentType {
  Html = 'Html'
}

export interface AvTableSearchState {
  filters?: Dictionary<any>;
  sort?: Sort;
  page?: PageEvent;
}

@Component({
  selector: 'lib-av-table',
  templateUrl: './av-table.component.html',
  styleUrls: ['./av-table.component.scss']
})
export class AvTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() componentConfig: AvTableComponentConfig;
  @Input() sortStartOrder?: string;
  @Input() trackBy?: TrackByFunction<any>;
  @Output() tableSearchChange = new EventEmitter<AvTableSearchState>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private tableSearch$: Observable<AvTableSearchState>;
  private filterChange$: Observable<Dictionary<any>>;
  private sortFilterChange$: Observable<[Dictionary<any>, Sort]>;
  private subscriptions: Subscription;

  displayedColumns: string[];
  footerColumns: string[];
  multiRowColumns: string[];
  tableFilters: FormGroup;
  sortChange$: EventEmitter<Sort>;
  pageChange$: EventEmitter<PageEvent>;
  filterControlMatChips$: Observable<Dictionary<AbstractControl>>;
  viewType: AvTableViewType;

  isAllSelected() {
    if (this.componentConfig.loadingData) return false;
    const { dataSelection, data } = this.componentConfig;
    return data.every((item) => dataSelection.selected.includes(item));
  }

  isIntermediate() {
    const { dataSelection, data } = this.componentConfig;
    const selectedSubset = data.filter((item) => dataSelection.isSelected(item));

    return !this.isAllSelected() && selectedSubset.length > 0;
  }

  masterToggle() {
    const { dataSelection, data } = this.componentConfig;

    this.isAllSelected()
      ? data.forEach(row => dataSelection.deselect(row))
      : data.forEach(row => dataSelection.select(row));
  }

  clearCheckboxes(propertyName: string) {
    const checkboxes = <FormGroup>this.tableFilters.get(propertyName);

    for (const key in checkboxes.controls) {
      checkboxes.controls[key].setValue(false);
    }
  }

  getColumnByPropertyName(propertyName: string) {
    return this.componentConfig.columns.find(column => column.propertyName === propertyName);
  }

  hasStickyColumn() {
    return this.componentConfig.columns.some(column => column.sticky === 'start');
  }


  private initialSearchState: AvTableSearchState;

  private setInitialSearchState() {
    const { columns, pagination, initialSearchState } = this.componentConfig;

    const filters = columns.reduce(
      (filters, column) => {
        switch (column.filterType) {
          case FilterTypes.text:
          case FilterTypes.number:
            filters[column.propertyName] = '';
            break;
          case FilterTypes.multiSelectLarge:
          case FilterTypes.multiSelect:
            filters[column.propertyName] = column.multiSelectItems.reduce(
              (keyDict, multiSelectItem) => {
                keyDict[multiSelectItem.value] = false;
                return keyDict;
              }, {}
            );
            break;
          case FilterTypes.range:
            filters[column.propertyName] = {
              min: null,
              max: null
            };
            break;
          default:
            break;
        }
        return filters;
      }, {}
    );

    this.initialSearchState = {
      filters,
      sort: {
        active: columns[0].propertyName,
        direction: <SortDirection> 'asc'
      }
    };

    if (this.componentConfig.pagination)
      this.initialSearchState.page = {
        length: pagination.totalItemsCount,
        pageSize: pagination.pageSize ?? 15,
        pageIndex: 0
      };

    if (initialSearchState) {
      this.initialSearchState = {...this.initialSearchState, ...initialSearchState};
    }
  }

  private setDisplayedColumns() {
    this.displayedColumns = this.componentConfig.columns.map((col) => col.propertyName);
    if (this.componentConfig.dataSelection)
      this.displayedColumns.unshift('Select');
    if (this.componentConfig.displayFooter)
      this.footerColumns = this.displayedColumns.slice();
    this.multiRowColumns = this.componentConfig.multiRow?.map(column => column.name) ?? [];
  }

  private buildTableFilterForm() {
    const filters = Object.keys(this.initialSearchState.filters).reduce((dict, key) => {
      const filter = this.initialSearchState.filters[key];
      if (typeof filter === 'object') {
        dict[key] = this.formBuilder.group(this.initialSearchState.filters[key]);
      } else {
        dict[key] = this.initialSearchState.filters[key]
      }
      return dict;
    }, {});

    this.tableFilters = this.formBuilder.group(filters);
  }

  private setObservables() {
    this.filterChange$ = this.tableFilters.valueChanges;

    this.sortChange$ = new EventEmitter<Sort>();
    this.pageChange$ = new EventEmitter<PageEvent>();

    this.filterControlMatChips$ = this.filterChange$.pipe(
      startWith(this.tableFilters.value),
      map((filterState) => {
        return Object.keys(filterState)
          .reduce((dict, propertyName) => {
            const filterValue = filterState[propertyName];

            if (typeof filterValue === 'object') {
              if (this.avHelperService.dictionaryHasTrue(filterValue))
                dict[propertyName] = this.tableFilters.get(propertyName);
            } else if (filterValue) {
              dict[propertyName] = this.tableFilters.get(propertyName);
            }

            return dict;
          }, {});
      })
    );

    const { page, sort } = this.initialSearchState;
    const debounceAmount = this.componentConfig.debounceTime || 0;

    this.tableSearch$ = combineLatest(
      this.filterChange$.pipe(startWith(this.tableFilters.value)),
      this.sortChange$.pipe(startWith(sort)),
      this.pageChange$.pipe(startWith(page))
    ).pipe(
      map(([filters, sort, page]): AvTableSearchState => {
        return { filters, sort, page };
      })
    );

    this.sortFilterChange$ = combineLatest(
      this.filterChange$.pipe(startWith(this.tableFilters.value)),
      this.sortChange$.pipe(startWith(sort))
    ).pipe(skip(1));
  }

  constructor(
    private formBuilder: FormBuilder,
    public avHelperService: AvHelperService
  ) { }

  ngOnChanges(changes) {
    const { currentValue, previousValue } = changes.componentConfig;

    //Keep multi select filters in sync if multi select items change
    if (previousValue) {
      for (let [index, column] of currentValue.columns.entries()) {
        if (column.multiSelectItems) {
          const newSelectItems = differenceWith(column.multiSelectItems, previousValue.columns[index].multiSelectItems, isEqual);
          const oldSelectItems = differenceWith(previousValue.columns[index].multiSelectItems, column.multiSelectItems, isEqual);
          const formGroup = this.tableFilters.get(column.propertyName) as FormGroup;

          for (let newItem of newSelectItems) {
            formGroup.addControl(newItem.value, new FormControl(false));
          }

          for (let oldItem of oldSelectItems) {
            formGroup.removeControl(oldItem.value);
          }
        }
      }
    }
  }

  ngOnInit() {
    this.viewType = this.componentConfig.viewType || AvTableViewType.FunnelFilters;

    this.setInitialSearchState();
    this.buildTableFilterForm();
    this.setDisplayedColumns();
    this.setObservables();

    this.subscriptions = this.tableSearch$.subscribe((searchState) => {
      this.tableSearchChange.emit(searchState);
    });
  }

  ngAfterViewInit() {
    if (this.componentConfig.pagination)
      this.subscriptions.add(this.sortFilterChange$.subscribe(() => {
        this.paginator.firstPage();
      }));
  }

  ngOnDestroy() { this.subscriptions.unsubscribe() }
}
